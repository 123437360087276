import axiosInstance, { AxiosAbortController } from 'src/axiosConfig'

export const initialExportGroupRequest = async (study) => {
	try {
		const res = await axiosInstance.request({
			url: `v1/exportRequest/${study}`,
			source: AxiosAbortController.signal,
			method: 'post',
			data: {
				options: {
					noImages: true,
				},
			},
		})
		return res.data
	} catch (err) {
		console.log('No good')
	}
}

export const pollExportGroupRequest = async (study, uuid) => {
	try {
		const res = await axiosInstance.request({
			url: `v1/exportRequest/${study}/${uuid}`,
			source: AxiosAbortController.signal,
			method: 'get',
		})
		return res.data
	} catch (err) {
		console.log('No good')
	}
}

export const programExport = async (patientsIds) => {
	try {
		const res = await axiosInstance.request({
			url: `/v3/export`,
			source: AxiosAbortController.signal,
			method: 'post',
			data: {
				patients: patientsIds,
			},
		})
		return res.data
	} catch (err) {
		console.log(err)
	}
}

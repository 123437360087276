import * as React from 'react'
import { IconButton, Stack, TextField } from '@mui/material'
import { Done, Close, EditCalendarSharp } from '@mui/icons-material'
import moment from 'moment-timezone'

export default function DateSelector({
	disabled,
	patient,
	formattor,
	onUpdateDateColonoscopyDate,
	timezone = 'America/Los_Angeles',
}) {
	const VisualFormat = 'MM/DD, HH:mm, z'
	const DatePickerFormat = 'YYYY-MM-DDTHH:mm'
	const [colonoscopyDate, setColonoscopyDate] = React.useState(formattor(patient?.metadata))
	const [isEditing, setIsEditing] = React.useState(false)
	const [isHoveredOver, setIsHoveredOver] = React.useState(false)

	const handleSubmit = () => {
		setIsEditing(false)
		setIsHoveredOver(false)
		onUpdateDateColonoscopyDate(colonoscopyDate)
	}

	return (
		<div>
			{isEditing ? (
				<Stack display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'row'} gap={1}>
					<TextField
						type="datetime-local"
						size="small"
						value={moment(colonoscopyDate).format(DatePickerFormat)}
						onChange={(e) => {
							setColonoscopyDate(e.target.value)
						}}
					/>
					<IconButton
						color="primary"
						onClick={() => {
							setIsEditing(false)
							setIsHoveredOver(false)
						}}
					>
						<Close fontSize={'small'} />
					</IconButton>
					<IconButton
						color="primary"
						onClick={() => {
							handleSubmit()
						}}
					>
						<Done fontSize={'small'} />
					</IconButton>
				</Stack>
			) : (
				<span onMouseEnter={() => setIsHoveredOver(true)} onMouseLeave={() => setIsHoveredOver(false)}>
					<Stack
						display={'flex'}
						justifyContent={'center'}
						alignItems={'center'}
						flexDirection={'row'}
						gap={2}
					>
						<div style={{ whiteSpace: 'nowrap' }}>
							{formattor(patient.metadata) && moment.utc(formattor(patient.metadata)).isValid() && (
								<span>{moment.utc(formattor(patient.metadata)).format(VisualFormat)}</span>
							)}
						</div>
						<IconButton
							disabled={!isHoveredOver}
							color="primary"
							style={{ opacity: isHoveredOver ? 1 : 0.5 }}
							onClick={() => setIsEditing(true)}
						>
							<EditCalendarSharp fontSize={'small'} />
						</IconButton>
					</Stack>
				</span>
			)}
		</div>
	)
}

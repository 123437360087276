import axiosInstance, { AxiosAbortController } from 'src/axiosConfig'

export const sendNotification = ({ userIds, title, message, imageUrl }) => {
	let data = {
		payload: {
			userIds: userIds, //'["auth0|65022f049f17d4419fc83ef2","google-oauth2|102431110696955347472"]',
			notification: {
				title: title, //'This a message',
			},
		},
	}
	if (message) {
		data = {
			...data,
			payload: {
				...data.payload,
				notification: {
					...data.payload.notification,
					message: message,
				},
			},
		}
	}
	if (imageUrl) {
		data = {
			...data,
			payload: {
				...data.payload,
				notification: {
					...data.payload.notification,
					imageUrl: imageUrl,
				},
			},
		}
	}
	return axiosInstance.request({
		url: `/v3/send/notification`,
		source: AxiosAbortController.signal,
		method: 'post',
		data: data,
	})
}

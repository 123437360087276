import React, { useEffect, useMemo, useState } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import * as actions from 'actions'
import { connect } from 'react-redux'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import TabBar from 'molecules/TabBar'
import ProgramTable from './ProgramTable'
import Content from '@components/Content'
import { paths } from 'routes'
import { navigate } from 'gatsby'
import { cacheDuration } from '../../../environment'
import { PROGRAM_IDS } from '../../../constants'
import {
	Button,
	CircularProgress,
	FormControl,
	IconButton,
	InputLabel,
	Link,
	MenuItem,
	Select,
	Stack,
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { fetchPrograms } from '../../../apis/patient'
import { NavigateBefore, NavigateNext } from '@mui/icons-material'
import { NumberParam, StringParam, useQueryParam } from 'use-query-params'
import { configuredStore, storage } from '/src/configureStore'
import moment from 'moment'

const PREFIX = 'ProgramContent'

const classes = {
	root: `${PREFIX}-root`,
	roleContainer: `${PREFIX}-roleContainer`,
	tabs: `${PREFIX}-tabs`,
	expand: `${PREFIX}-expand`,
	inlineTab: `${PREFIX}-inlineTab`,
	distinguishedTab: `${PREFIX}-distinguishedTab`,
	tabsBackground: `${PREFIX}-tabsBackground`,
	tabPanel: `${PREFIX}-tabPanel`,
	titleBar: `${PREFIX}-titleBar`,
}

const Root = styled('div')(({ theme }) => ({
	[`&.${classes.root}`]: {
		padding: theme.spacing(4),
	},

	[`& .${classes.roleContainer}`]: {
		paddingTop: theme.spacing(4),
	},

	[`& .${classes.expand}`]: {
		flexGrow: 1,
		padding: 0,
		// marginBottom: theme.spacing(1),
	},

	[`& .${classes.inlineTab}`]: {},

	[`& .${classes.distinguishedTab}`]: {
		margin: 15,
		borderColor: 'white',
		borderWidth: 1,
		borderStyle: 'solid',
	},

	[`& .${classes.tabsBackground}`]: {
		minWidth: 190,
	},

	[`& .${classes.tabPanel}`]: {
		overflow: 'auto',
	},

	[`& .${classes.titleBar}`]: {
		padding: theme.spacing(1),
		borderRadius: 10,
	},
}))

const ProgramContent = ({
	location,
	auth,
	program,
	patient,
	getStudies,
	addProgramSurvey,
	setCurrentPatient,
	fetchPatientPicture,
	getPatientData,
	onChatPatient,
	setCurrentProgramTabName,
	setProgramTableSearchValue,
	setProgramTableSort,
	getUserPrograms,
	setUpdateMeta,
}) => {
	const programs = patient.programs || []
	const [currentTab, setCurrentTab] = useState(0)
	const [allPrograms, setAllPrograms] = useState([])
	const [pageSize, setPageSize] = useState(10000)
	const [notFound, setNotFound] = useState(false)
	const [programCode, setProgramCode] = useQueryParam('program', StringParam)
	const [pageNumberFromURL, setPageNumberFromURL] = useQueryParam('page', NumberParam)
	const [searchString, setSearchString] = useQueryParam('searchString', StringParam)
	const [page, setPage] = useState(pageNumberFromURL || 0)

	const onTabChange = async (newValue, tabName) => {
		setCurrentTab(tabName.props.value)
		setPageNumberFromURL(0)
		setPage(0)
		setSearchString('')
		setProgramTableSearchValue('')
		const updatedProgramCode = allPrograms[tabName.props.value].code
		setProgramCode(updatedProgramCode)
		getStudies({
			program_code: updatedProgramCode,
			include_users: 1,
			upage: 1,
			page_size: pageSize,
		})
	}

	const currentProgram = useMemo(() => {
		return patient.programs.find((p) => p.code === programCode) || programs[0]
	}, [programs, currentTab, patient.loading?.read, allPrograms])

	useEffect(() => {
		setAllPrograms(patient?.programs)
		const activeProgramIndex = patient?.programs?.findIndex((program) => program?.code === currentProgram.code)
		if (activeProgramIndex !== -1) {
			getStudies({
				program_code: currentProgram.code,
				include_users: 1,
				upage: 1,
				page_size: pageSize,
			})
		} else {
			setNotFound(true)
		}
	}, [])

	useEffect(() => {
		if (patient?.programs) {
			setAllPrograms(patient?.programs)
		}
	}, [patient?.loading?.read])

	useEffect(() => {
		if (allPrograms.length > 0) {
			const activeProgramIndex = allPrograms.findIndex((program) => program?.code === currentProgram.code)
			setCurrentTab(activeProgramIndex)
		}
	}, [allPrograms])

	const sortedProgram = useMemo(() => {
		return (allPrograms || []).sort((a, b) => a.name.localeCompare(b.name))
	}, [allPrograms])

	const activeProgramsTimezone = useMemo(() => {
		const timezone = currentProgram?.config?.timezone
		return timezone ? timezone : ''
	}, [currentProgram])

	const onUpdateDateColonoscopyDate = (patient, colonoscopyDate) => {
		setUpdateMeta({
			programId: currentProgram.id,
			sub: patient.key,
			user_id: patient.id,
			...patient.metadata,
			timezone: activeProgramsTimezone,
			colonoscopyDate: moment(colonoscopyDate).toDate(),
		})
	}

	const onUpdatePrepType = (patient, prepType) => {
		setUpdateMeta({
			programId: currentProgram.id,
			user_id: patient.id,
			sub: patient?.key,
			...patient.metadata,
			prepType,
		})
	}

	return (
		<Root className={classes.root}>
			<AppBar className={classes.titleBar} position="static" color="default" elevation={0}>
				<Toolbar>
					<Grid container direction="row" justifyContent="flex-end" alignItems={'center'}>
						<Grid item xs={5}>
							<Typography variant="h5" color="textSecondary" component="h5">
								Active Programs
							</Typography>
						</Grid>
						<Grid item xs={4} display={'flex'} flexDirection={'row'} justifyContent={'end'} px={3}>
							<>
								{activeProgramsTimezone &&
									(patient?.loading?.read ? (
										<CircularProgress size={20} />
									) : (
										<>
											<Typography variant="body1" color="textSecondary" component="h5">
												Timezone: {activeProgramsTimezone}
											</Typography>
										</>
									))}
							</>
						</Grid>
						<Grid item xs={3}>
							{allPrograms.length > 0 && (
								<FormControl fullWidth>
									<InputLabel id="programSelectorLabel">Select Program</InputLabel>
									<Select
										labelId="programSelectorLabel"
										value={currentTab}
										label="Select Program"
										onChange={onTabChange}
									>
										{sortedProgram.map((program, index) => (
											<MenuItem key={program?.code} value={index}>
												{program?.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							)}
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
			<Grid container className={classes.tabPanel} marginTop={2}>
				{!notFound ? (
					<div className={classes.expand}>
						{currentProgram?.members?.length ? (
							<>
								{currentProgram.id === PROGRAM_IDS.MAYO ? (
									<Grid
										display={'flex'}
										justifyContent={'flex-end'}
										gap={1}
										marginY={2}
										alignItems={'center'}
									>
										{/* <Link href="https://hipaa.jotform.com/tables/221953046896163" target="_blank">
										View Survey Submissions <FontAwesomeIcon icon={faExternalLinkAlt} />
									</Link> */}
										<span>View Survey Submissions:</span>
										<Button
											variant="contained"
											onClick={() => navigate(`/jotform/${currentProgram.id}/230946300820145`)}
										>
											Daily Survey (new)
										</Button>
										<Button
											variant="contained"
											onClick={() => navigate(`/jotform/${currentProgram.id}/223064320902141`)}
										>
											Daily Survey (old)
										</Button>
										<Button
											variant="contained"
											onClick={() => navigate(`/jotform/${currentProgram.id}/221953046896163`)}
										>
											5-Day Survey
										</Button>
									</Grid>
								) : null}
								{currentProgram.id === PROGRAM_IDS.UMDIET001 ? (
									<Grid
										display={'flex'}
										justifyContent={'flex-end'}
										gap={1}
										marginY={2}
										alignItems={'center'}
									>
										<span>View Survey Submissions:</span>
										<Button
											variant="contained"
											onClick={() => navigate(`/jotform/${currentProgram.id}/230874173929062`)}
										>
											Day 0 & 28
										</Button>
										<Button
											variant="contained"
											onClick={() => navigate(`/jotform/${currentProgram.id}/230875427727061`)}
										>
											Daily
										</Button>
										<Button
											variant="contained"
											onClick={() => navigate(`/jotform/${currentProgram.id}/230875533236155`)}
										>
											Weekly
										</Button>
									</Grid>
								) : null}
								{currentProgram.id === PROGRAM_IDS.YAKULT2023 ? (
									<Grid
										display={'flex'}
										justifyContent={'flex-end'}
										gap={1}
										marginY={2}
										alignItems={'center'}
									>
										<span>View Survey Submissions:</span>
										<Button
											variant="contained"
											onClick={() => navigate(`/jotform/${currentProgram.id}/232254275236050`)}
										>
											Daily Survey
										</Button>
									</Grid>
								) : null}

								{currentProgram.id === PROGRAM_IDS.CS2586 ? (
									<Grid
										display={'flex'}
										justifyContent={'flex-end'}
										gap={1}
										marginY={2}
										alignItems={'center'}
									>
										<span>View Survey Submissions:</span>
										<Button
											variant="contained"
											onClick={() => navigate(`/jotform/${currentProgram.id}/230448697048062`)}
										>
											Daily Survey
										</Button>
									</Grid>
								) : null}

								<ProgramTable
									onUpdateDateColonoscopyDate={onUpdateDateColonoscopyDate}
									onUpdatePrepType={onUpdatePrepType}
									patients={currentProgram.members}
									programSurveys={currentProgram.programSurveys || []}
									addProgramSurvey={addProgramSurvey}
									setCurrentPatient={setCurrentPatient}
									fetchPatientPicture={fetchPatientPicture}
									searchValue={program.programTableSearchValue}
									setSearchValue={setProgramTableSearchValue}
									sortPreferences={program.programTableSort}
									setSortPreferences={setProgramTableSort}
									onSetCurrentPatient={() => {
										navigate(paths.diary)
									}}
									onChatPatient={(patient, SB) => {
										onChatPatient(patient, SB)
										navigate(paths.chat)
									}}
									currentProgram={currentProgram}
									getStudies={getStudies}
									pageNumberFromURL={pageNumberFromURL}
									page={page}
									setPageNumberFromURL={setPageNumberFromURL}
									setPage={setPage}
									searchString={searchString}
									setSearchString={setSearchString}
								/>
							</>
						) : (
							<Content
								title={'No people are enrolled in your program!'}
								subtitle={'Have your patents enroll via the Dieta App'}
							/>
						)}
					</div>
				) : (
					<Grid item width={'100%'}>
						<Content
							title={'Sorry wrong Program Code'}
							subtitle={"You do not have access to this program code or it doesn't exist"}
						/>
					</Grid>
				)}
			</Grid>
		</Root>
	)
}

ProgramContent.propTypes = {
	location: PropTypes.object,
	auth: PropTypes.shape({}),
	patient: PropTypes.shape({
		current: PropTypes.shape({
			id: PropTypes.number,
		}),
		data: PropTypes.array,
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}),
	getStudies: PropTypes.func.isRequired,
	getStudies: PropTypes.func.isRequired,
	setCurrentPatient: PropTypes.func.isRequired,
	getPatientData: PropTypes.func.isRequired,
	fetchPatientPicture: PropTypes.func.isRequired,
	onChatPatient: PropTypes.func.isRequired,
	getUserPrograms: PropTypes.func.isRequired,
	setUpdateMeta: PropTypes.func.isRequired,
}

const mapStateToProps = ({ auth, patient, program, setUpdateMeta }) => {
	return {
		auth,
		patient,
		program,
		setUpdateMeta,
	}
}

export default connect(mapStateToProps, actions)(ProgramContent)

import * as React from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { navigate } from 'gatsby'

export default function SurveyMenu({ disabled, program_id, items }) {
	const [anchorEl, setAnchorEl] = React.useState(null)
	const open = Boolean(anchorEl)
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleNavigate = (jotform_form_id) => {
		handleClose()
		navigate(`/jotform/${program_id}/${jotform_form_id}`)
	}

	return (
		<>
			<Button
				disabled={disabled}
				id="demo-customized-button"
				aria-controls={open ? 'demo-customized-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				variant="contained"
				color="primary"
				onClick={handleClick}
				endIcon={<KeyboardArrowDownIcon />}
				style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
			>
				Survey Results
			</Button>
			<Menu
				id="demo-customized-menu"
				MenuListProps={{
					'aria-labelledby': 'demo-customized-button',
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
			>
				{items?.map(({ name, jotform_form_id }) => {
					return (
						<MenuItem onClick={() => handleNavigate(jotform_form_id)} disableRipple>
							{name}
						</MenuItem>
					)
				})}
			</Menu>
		</>
	)
}

import * as React from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { navigate } from 'gatsby'
import { useMemo } from 'react'

export default function PrepTypeSelector({ disabled, items, patient, onUpdatePrepType }) {
	const [anchorEl, setAnchorEl] = React.useState(null)
	const open = Boolean(anchorEl)
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleNavigate = (updatedPrepType) => {
		onUpdatePrepType(updatedPrepType)
		handleClose()
	}

	const currentPrepType = useMemo(() => {
		if (patient && patient?.metadata && patient?.metadata?.prepType) {
			return patient?.metadata?.prepType
		}
	}, [patient?.metadata?.prepType])

	return (
		<>
			<Button
				disabled={disabled}
				id="demo-customized-button"
				aria-controls={open ? 'demo-customized-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				variant="outlined"
				color={currentPrepType ? 'success' : 'error'}
				onClick={handleClick}
				endIcon={<KeyboardArrowDownIcon />}
			>
				{currentPrepType || 'Select Prep Type'}
			</Button>
			<Menu
				id="demo-customized-menu"
				MenuListProps={{
					'aria-labelledby': 'demo-customized-button',
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
			>
				{items?.map(({ name }) => {
					return (
						<MenuItem onClick={() => handleNavigate(name)} disableRipple>
							{name}
						</MenuItem>
					)
				})}
			</Menu>
		</>
	)
}
